<template>
  <a-button class="editable-add-btn" type="primary" @click="openModal" style="margin-bottom: 16px">Добавить страницу</a-button>
  <a-modal
    class="doc-create-modal"
    title="Добавить документацию"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="doc" layout="vertical">
      <div class="col-md-12">
        <a-form-item label="Название страницы">
          <a-input
            ref="docName"
            placeholder="Введите название"
            v-model:value="doc.title"
          />
        </a-form-item>
        <a-form-item label="Короткая ссылка">
          <a-input
            placeholder="Введите ссылку"
            v-model:value="doc.slug"
          />
        </a-form-item>
        <a-form-item label="Иконка">
          <a-input
            placeholder="Введите название иконки"
            v-model:value="doc.logo"
          />
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, nextTick} from 'vue';
import apiClient from "@/services/axios";

const emit = defineEmits(['reload']);

let loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  docName = ref("docName"),
  doc = reactive({
    title: '',
    slug: '',
    logo: '',
  });

const
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => docName.value?.focus());
  },
  resetForm = () => {
    Object.assign(doc, {
      title: '',
      slug: '',
      logo: '',
    });
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(doc);

    return apiClient
      .post('/documentations/create', model)
      .then(function (response) {
        if (response) {
          resetForm()
          modalVisible.value = false;
          confirmLoading.value = false;
          emit('reload')
        }
      })
      .catch(() => {
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
        // console.log(err)
      })
      .finally(() => {
        confirmLoading.value = false;
        emit('reload')
      })
  }
</script>

<style scoped>
@import "./create.css";
</style>
